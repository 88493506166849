import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Page, Metadata } from '~/components/Layout'
import LargeIntro from '../sections/Landing/LargeIntro'
import RichResults from '~/components/RichResults'
import SidebarLayout from '../components/Layout/SideBar'
import TableOfContents from '../components/Generic/TableOfContents'
import FAQ from '../sections/Care/FAQ'
import { questionAnswerSets } from '../utils/question-answer-sets'
import { openGorgiasChatHandler } from '../utils/open-gorgias-chat'

const FaqPage = ({data}) => {
  const { page } = data
  const byType = section => section.type === 'landing/LargeIntro'
  const byCategories = section => section.type === 'care/FAQ'
  const header = page.sections.filter(byType)[0]
  const footer = page.sections.filter(byType)[1]
  const categories = page.sections.filter(byCategories)

  return (
    <Page>
      <Metadata
        title={page.metadata && page.metadata.title}
        description={page.metadata && page.metadata.description.description}
        image={page.metadata && page.metadata.image.file.url}
      />
      <RichResults richResults={questionAnswerSets(page)} />
        {header && <LargeIntro section={header} />}
        <SidebarLayout>
            <TableOfContents section={categories}/>
            <section>
              {categories.map(section => <FAQ section={section} key={section.id} />)}
            </section>
        </SidebarLayout>
        {footer && <LargeIntro section={footer} onClick={openGorgiasChatHandler} />}
    </Page>
  )
}

export default FaqPage

export const query = graphql`
  query FaqPage($locale: String) {
    page: contentfulPage(slug: { eq: "faq" }, node_locale: { eq: $locale }) {
      ...PageFragment
    }
  }
`

FaqPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      name: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string
          })
        })
      })
    })
  })
}
