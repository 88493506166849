import React from 'react'
import PropTypes from 'prop-types'
import { Aside, Nav, Anchor } from './styles'

const TableOfContents = ({section}) => {
    return (
        <Aside>
            <Nav>
                {section.map( (section, index) =>
                    <li key={section.id} >
                        <Anchor
                          activeClass="active" spy={true} 
                          smooth={true} duration={1250} 
                          offset={index === 0 ? -200 : -300} 
                          to={`${section.heading.toLowerCase().replace(/ /g,"-")}`}
                        >
                            {section.heading}
                        </Anchor>
                    </li>   
                )}
            </Nav>
        </Aside>
    )
}

export default TableOfContents

TableOfContents.propTypes = {
    seciton: PropTypes.shape({
        id: PropTypes.string,
        heading: PropTypes.string
    })
}