import styled from '@emotion/styled'
import { Link } from 'react-scroll'

export const Aside = styled.aside`
    display:none;
    position:relative;
    z-index:1;
    margin-block-end:300px;
    min-width:350px; 
    @media screen and (min-width: 992px) {
        display:block;
        grid-area:sidebar;
    }
`
export const Nav = styled.nav`
    position:sticky;
    top:130px;
    padding-inline-start: 100px;
    padding-block-start:50px; 
    list-style-type: none;
    display:grid;
    grid-row-gap:1rem;
    font-size:18px;
`

export const Anchor = styled(Link)`
    cursor: pointer;
    &.active {
        font-weight:bold;
    }
`