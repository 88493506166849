export const questionAnswerSets = (data = []) => {
    let qaSets = []
    
    data.sections.forEach(set => {
      if(set.content){
        qaSets.push(set.content)
      }
    })
    
    qaSets = qaSets.flat()

    const initialValue = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
    } 

    for(let i = 0; i < qaSets.length; i++){
        initialValue.mainEntity.push({
            "@type": "Question",
            "name": qaSets[i]?.question?.question,
            "acceptedAnswer" :  {
              "@type": "Answer",
              "text": qaSets[i]?.answer?.answer
            }
        })
      }
    
    return initialValue
}